
body { 
 ./* padding-top: 60px;*/
  font-family: 'Lato', arial, sans-serif; 
  font-size: 16px;
  color: #444444;
  background-color: #FFFFFF !important;
  -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; 
}
.text-white{
  color: #ffffff !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
}
/* Material Design Dropdown */
.navbar .dropdown-menu, .navbar.navbar-default .dropdown-menu {
  margin-left: 20px;
}
/*Form error message*/
.form-group label {
  color: #D84440;
  font-size: 14px;
}
.form-group.is-focused label, .form-group.is-focused label.control-label {
    color: #D84440;
}
/*  Logo Resize */
.navbar-brand > img {
  height: 100%;
  max-width: 100%;
}
/*slider*/
.edslider {top: 61px;}
/* Navbar*/
/*.navbar {height: 95px;}*/
.navbar-right { margin-top: 1px;}
/* promo section */
#promo-title {font-size: 38px;color:#ffffff;}
.promo { 
  /*background: url(/images/background/promo-bg.jpg) no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;*/
  /*margin-top: 30px;*/
}
/*.promo .intro {
  color: #fff;
  padding-top: 30px;
}
.promo .intro .title {
  color: #fff;
  font-size: 38px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  max-width: 720px;
  font-weight: bold;
  margin-bottom: 15px;
}
.promo .intro .summary {
  color: #fff;
  font-size: 22px;
  max-width: 680px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  margin-bottom: 15px;
}
.promo .fixed-container {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.promo .signup {
  background: rgba(0, 0, 0, 0.2);
  padding: 30px 0;
  padding-bottom: 45px;
  color: #fff;
}
.promo .signup .title {
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 15px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.6);
}
.promo .signup .summary {
  color: #fff;
  font-size: 16px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
}*/
.promo .social {
  background: #28a5a8;
  padding: 8px 0;
  -webkit-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
  color: #fff;
  font-size: 13px;
  height: 30%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  /*top: 70%;*/
  width: 100%;
}

.promo .social .line {
  position: relative;
  top: -4px;
  display: inline-block;
  margin-right: 10px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}
.promo .social .twitter-tweet {
  display: inline-block;
  position: relative;
  top: 2px;
}
.promo .social .fb-like {
  display: inline-block;
  position: relative;
  top: 2px;
}
.animated.fadeInRight.summary {
    color: #ffffff;
    padding-top: 8px;
    font-size: 26px;
}
.social .btn.btn-danger.btn-raised.btn-lg {
    position: absolute;
    top: 65px;
  }
  #promo .edslider .controls {top: 102%;}
  #promo .edslider .controls .paginator li {background: #cccccc none repeat scroll 0 0;}
  #promo .edslider .controls .paginator li.current, .edslider .controls .paginator li:hover, .edslider .progress { background: #28a5a8 none repeat scroll 0 0;}
/* footer section */

.footer {
    background: #28a5a8 none repeat scroll 0 0;
    color: #fff;
    padding: 15px 0;
    height: auto;
    padding-bottom: 0;
}

.footer .footer-col .title {
    color: #0b2c2c;
    font-size: 18px;
    font-weight: normal;
}
.footer .footer-col a {
    color: #0f4041;
   
}
.footer .btn.btn-red {
      color: #fff;
   
}
.contact {font-size: 19px;}
.footer .footer-col.contact .material-icons {
    color: #0f4041;
    margin-right: 10px;
}
.footer .bottom-bar {
  background: #444444;
  color: #ffffff;
  font-size: 14px;
  padding: 11px 0 3px;
  margin-top: 30px;
}
.footer .bottom-bar .social li {
    float: right;
    margin-left: 10px;
    text-align: right;
}
.footer .bottom-bar .social li a .fa {
    color: #b3b3b3;
    font-size: 20px;
}
.footer .bottom-bar .social li a .material-icons {
    color: #b3b3b3;
    font-size: 20px;
}
.footer .bottom-bar .copyright {
  line-height: 1.6;
}
.footer .footer-col.links .fa {
    margin-right: 5px;
}
.footer-col-inner {
    margin-bottom: 15px;
}
.form-horizontal{
  margin-top:-20px;
  padding-right:8px;
  padding-left:8px;
  background-color:#ffffff;
  border-radius:5px;
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.testimonials .form-horizontal {margin-bottom: 30px;}
/* footer form section */
.footer .form-horizontal {
  background-color: #28a5a8;
  box-shadow:none;
}
.footer .form-group label.control-label {
    color: #0f4041;
    font-size: 13px;
}
#footer-contact-form .form-group .form-control{
  background-color: #fff !important;
  background-image: none;
  transition-duration:initial;
  text-indent: 9px;
}
#textArea {height: 25%;}
#footer-contact-form .col-md-2 {padding-right: 0px;}
#footer-contact-form .col-md-4 {padding-left: 0px;padding-right: 0px;}

.phone_call {
  margin: 17px;
}
input[type="checkbox"] + label {
    background: rgba(0, 0, 0, 0) url("/images/check.png") no-repeat scroll 0 0;
    color: #28a5a8;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    height: 15px;
    line-height: 1.1;
    margin: 4px 26px 7px 0 !important;
    white-space: nowrap;
    width: 52%;
}

}
@media screen and (max-width: 640px) {
  .footer .bottom-bar .social li {
    float: left;
    margin-left: 0;
    margin-right: 10px;
}
.footer .bottom-bar .copyright {
    margin-bottom: 15px;
}
#footer-contact-form .col-md-6 {padding-left: 0px;padding-right: 0px;}
}
@media screen and (min-width: 767px) {
  .col-sm-2.name {
    padding-bottom: 11px;
    padding-top: 11px;
}
.col-sm-3.name {
    padding-bottom: 11px;
    padding-top: 11px;
  }
.alert.alert-danger.alert-warning{
  margin-left: -23px;
}
.alert.alert-success.alert-confirm{
  margin-left: -23px;
  white-space: nowrap;
}
.alert.alert-warning.alert-reject{
  margin-left: -23px;
  white-space: nowrap;
  background-color: #ffe1ae;
  color: #ff5722;
}

}
/* ======= Why Section ======= */
.panel.panel-default > a {
    color: #444;
    text-decoration: none;
}
.panel.panel-default > a:focus {outline: none;}
.why {
    padding-top: 8%;
}
.why .title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 15px;
    margin-top: 0;
}
.why .intro {
    color: #999999;
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 30px;
}

.why .benefits,.why .whois  {
    padding: 60px 0;
    padding-top:0px;
}

.why .benefits .item,.why .whois .item  {
    margin-bottom: 0px;
    padding: 15px 15px 0;
}
.why .benefits .item .title {
    color: #28a5a8;
    font-size: 22px;
    margin-bottom: 15px;
    text-align: left;
}
.why .whois .item .title {
  color: #28a5a8;
  font-size: 22px;
  margin-bottom: 24px;
  text-align: center;}
.why .whois .panel { border-radius: 7px;background-color: rgb(223, 242, 242);}
.why .whois .panel-body > h3 {
  margin-bottom: 0;
  margin-top: -6px;
  font-size: 22px;
  }
.why .whois .panel-body p {margin-bottom: -6px;}
.why .whois .panel-body a {color:#f44336;}
.why .benefits .item p {color: #444444;
    font-size: 16px;
    text-align: left;}
.why .benefits .item .desc {
  color: #666666;
}

.why .benefits .item .icon {
  float: left;
}
.why .benefits .item .icon .fa {
    color: #cccccc;
    font-size: 58px;
    margin-top: 5px;
}
.why .benefits .item .content,.why .whois .item .content{
  margin-top:10px;
}
.why .benefits .item:hover .icon .fa {
  color: #999999;
}

.why .benefits .item.last {
  margin-bottom: 20px;
}
.why .testimonials .item {
  padding-top: 22px;
  margin-bottom: 0px;
}
.why .testimonials .item.last {
  margin-bottom: 0;
}
.why .testimonials .lois-free-img {text-align: center;padding-bottom: 15px;}
.why .testimonials .item .quote-box {
  background: #f5f5f5;
  padding: 30px;
  padding-bottom: 15px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  position: relative;
  margin-bottom: 30px;
}
.why .testimonials .item .quote-box .fa {
  color: #49d0d4;
  font-size: 18px;
  position: absolute;
  right: 30px;
  bottom: 30px;
}
.why .testimonials .item .quote-box:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #f5f5f5;
  position: absolute;
  bottom: -20px;
  margin-left: -20px;
  left: 50%;
}
.why .testimonials .item .quote {
  border: none;
  padding: 0;
  font-size: 16px;
  color: #666666;
  margin-bottom: 15px;
}
.why .testimonials .item .details {
  font-size: 13px;
}
.why .testimonials .item .details .name {
  display: block;
  font-weight: normal;
  font-family: 'Montserrat', sans-serif;
}
.why .testimonials .item .details .title {
  display: block;
  color: #b3b3b3;
  margin-bottom: 0;
  font-weight: normal;
  font-size: 13px;
}
.why .testimonials .item .people .user-pic {
  max-width: 122px;
  max-height: 122px;
}
.why .testimonials .item .people .user-pic,
.why .testimonials .item .people .user-pic *,
.why .testimonials .item .people .user-pic *:before,
.why .testimonials .item .people .user-pic *:after {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.signup {
    padding: 80px 0; 
}

.signup .title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 30px;
  margin-top: 0;
}
.signup .summary {
    color: #999999;
    font-size: 18px;
    margin-bottom: 30px;
}
.benefits .icon{
    height: 80px;
}
a:hover {
    color: #19686a;
    text-decoration: none;
}

/* ======= Modal ======= */

.modal.modal-video .modal-dialog {
  width: 760px;
  margin: 30px auto;
}
.modal.modal-video .modal-body {
  padding: 10px;
  padding-top: 0;
}
.modal.modal-video .modal-header {
  border: none;
  padding: 10px;
}
.modal.modal-video .modal-title {
  color: #0f4041;
}
.modal.modal-login .modal-dialog,
.modal.modal-signup .modal-dialog {
  width: 430px;
  margin: 30px auto;
}
.modal.modal-logout .modal-dialog {
  width: 460px;
  margin: 30px auto;
}
.modal.modal-logout .modal-header {
    border: medium none;
    padding: 32px;
}
.modal.modal-logout .modal-body {
    padding: 0 30px 30px;
}
.modal.modal-logout .modal-footer {
    background: #f5f5f5 none repeat scroll 0 0 padding-box;
    border-radius: 0 0 4px 4px;
    padding: 30px;
    text-align: center;
}
.modal.modal-logout .modal-footer p{
    color: #666666;
    font-size: 14px;
    padding-top:15px;
}
.modal.modal-login .modal-header,
.modal.modal-signup .modal-header {
  border: none;
  padding: 15px;
}
.modal.modal-login .modal-header .modal-title,
.modal.modal-signup .modal-header .modal-title {
  margin-top: 15px;
  margin-bottom: 15px;
}
.modal.modal-login .modal-body,
.modal.modal-signup .modal-body {
  padding: 30px;
  padding-top: 0;
}
.modal.modal-login .modal-footer,
.modal.modal-signup .modal-footer {
  padding: 30px;
  background: #f5f5f5;
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 4px;
  -moz-border-radius-bottomleft: 4px;
  -moz-border-radius-topleft: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  text-align: center;
}
.modal.modal-login .modal-footer p,
.modal.modal-signup .modal-footer p {
  color: #666666;
  font-size: 14px;
  margin-bottom: 0;
}
.modal.modal-login .form-group,
.modal.modal-signup .form-group {
  position: relative;
}
.modal.modal-login .form-group.email:before,
.modal.modal-signup .form-group.email:before {
  content: "\f007";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 10px;
  top: 12px;
  color: #999999;
}
.modal.modal-login .form-group.password:before,
.modal.modal-signup .form-group.password:before {
  content: "\f023";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 10px;
  top: 12px;
  color: #999999;
}
.modal.modal-login .form-control,
.modal.modal-signup .form-control {
  font-size: 16px;
  padding-left: 30px;
}
.modal.modal-login .social-login,
.modal.modal-signup .social-login {
  margin-bottom: 15px;
}
.modal.modal-login .social-login li,
.modal.modal-signup .social-login li {
  margin-bottom: 10px;
}
.modal.modal-login .social-login li .btn,
.modal.modal-signup .social-login li .btn {
  color: #fff;
}
.modal.modal-login .social-login li .btn:hover,
.modal.modal-signup .social-login li .btn:hover {
  color: #fff;
}
.modal.modal-login .social-login li .btn .fa,
.modal.modal-signup .social-login li .btn .fa {
  font-size: 18px;
  position: relative;
  top: 2px;
  margin-right: 10px;
}
.modal.modal-login .twitter-btn,
.modal.modal-signup .twitter-btn {
  background: #55acee;
}
.modal.modal-login .twitter-btn:hover,
.modal.modal-signup .twitter-btn:hover {
  background: #2795e9;
}
.modal.modal-login .facebook-btn,
.modal.modal-signup .facebook-btn {
  background: #3b5998;
}
.modal.modal-login .facebook-btn:hover,
.modal.modal-signup .facebook-btn:hover {
  background: #2d4373;
}
.modal.modal-login .google-btn,
.modal.modal-signup .google-btn {
  background: #dd4b39;
}
.modal.modal-login .google-btn:hover,
.modal.modal-signup .google-btn:hover {
  background: #c23321;
}
.modal.modal-login .forgot-password,
.modal.modal-signup .forgot-password {
  font-size: 13px;
  margin-top: 10px;
}
.modal.modal-login .forgot-password a,
.modal.modal-signup .forgot-password a {
  color: #999999;
}
.modal.modal-login .forgot-password a:hover,
.modal.modal-signup .forgot-password a:hover {
  text-decoration: underline;
  color: #28a5a8;
}
.modal.modal-login .divider,
.modal.modal-signup .divider {
  text-align: center;
  position: relative;
  margin-bottom: 30px;
  color: #999999;
  text-transform: uppercase;
}
.modal.modal-login .divider span,
.modal.modal-signup .divider span {
  background: #fff;
  display: inline-block;
  padding: 0 10px;
  position: relative;
}
.modal.modal-login .divider:before,
.modal.modal-signup .divider:before {
  content: "";
  position: absolute;
  left: 0;
  top: 12px;
  background: #e5e5e5;
  height: 1px;
  width: 100%;
}
.modal.modal-login .note,
.modal.modal-signup .note {
  color: #999999;
  font-size: 13px;
  margin-bottom: 30px;
}
.modal.modal-login .btn + .note,
.modal.modal-signup .btn + .note {
  margin: 15px 0;
}
.modal.modal-login .remember label,
.modal.modal-signup .remember label {
  font-size: 14px;
  color: #666666;
}
.col-sm-3.signup-mail {
    padding-left: 6px;
}
/* ======= Tour Page ======= */
.features-promo {
    background: #28a5a8 url(/images/background/tour-bg.jpg) no-repeat scroll center bottom / cover ;
    min-height: 500px;
    position: relative;
}
.features-promo .bg-mask {
    background: #4A5454 none repeat scroll 0 0;
    height: 100%;
    left: 0;
    opacity: 0.7;
    position: absolute;
    top: 0;
    width: 100%;
}
.features-promo .features-intro {
    color: #fff;
    padding-top: 60px;
}
.features-promo .features-intro .title {
    color: #fff;
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 30px;
    max-width: 720px;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
}
.features-promo .features-intro .features-list {
    font-size: 18px;
    margin-left: 0;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}
.delayp3 {
    animation-delay: 0.3s;
    transition-delay: 0.3s;
}
.features-promo .features-intro .features-list li {
    margin-bottom: 15px;
}
.features-promo .features-intro .features-list li .fa {
    color: #145456;
    margin-right: 10px;
    text-shadow: none;
}
.features-promo .features-video {
    padding-top: 80px;
}
.delayp6 {
    animation-delay: 0.6s;
    transition-delay: 0.6s;
}
.fluid-width-video-wrapper {
    padding: 0;
    position: relative;
    width: 100%;
}
.fluid-width-video-wrapper iframe, .fluid-width-video-wrapper object, .fluid-width-video-wrapper embed {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}
.features {
    padding-top: 80px;
}

.features .item {
    padding: 60px 0;
}
.delayp1 {
    animation-delay: 0.1s;
    transition-delay: 0.1s;
}
.features .item .title {
    color: #28a5a8;
    font-size: 22px;
    margin-top: 0;
}
.features .item .content p {
    font-size: 16px;
}
.features .figure img {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}
/* ======= About Page ======= */
.safety_content {padding-top:1%;}
.safety_content .title {text-align:left;font-size:36px;color: #28a5a8;}
.safety_content p {color:#444444;text-align:left;font-size:18px;}
.personal-safety-feature-list ul {padding-left:15px;}
.personal-safety-feature-list ul > li {font-size:22px;}
.personal-safety-feature-list {padding-bottom: 10px;padding-top: 10px; }
.contact-main {
    padding-top: 80px;
}
.contact-main {
    padding-top: 100px;
}
.contact-main .item .item-inner {
    min-height: 300px;
}
.contact-main .item .item-inner {
    background: #f5f5f5 none repeat scroll 0 0 padding-box;
    border-radius: 4px;
    margin: 30px;
    min-height: 300px;
    padding: 30px 10px;
}
.contact-main .item .icon {
    background: #fff none repeat scroll 0 0 padding-box;
    border: 3px solid #28a5a8;
    border-radius: 50%;
    display: table;
    height: 90px;
    margin: 0 auto 30px;
    position: relative;
    transition: transform 0.2s ease-out 0s, background 0.3s ease 0s;
    width: 90px;
}
.contact-main .item .icon .fa {
    color: #28a5a8;
    display: table-cell;
    font-size: 48px;
    text-align: center;
    vertical-align: middle;
}
.contact-main .item .details {
    font-size: 18px;
}
.contact-main .title {
    font-size: 28px;
    margin-bottom: 30px;
    margin-top: 0;
}
.contact-main .intro {
    color: #999999;
    margin-bottom: 30px;
}
.contact-main .item.last {
    margin-bottom: 60px;
}
/* ======= TermsServices Page ======= */
.price-plan {
    padding: 80px 0;
}
.price-plan .title {
    font-size: 28px;
    margin-bottom: 15px;
}
.price-plan .intro {
    color: #999999;
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 30px;
}
.faq.has-bg-color {
    background: #f5f5f5 none repeat scroll 0 0;
}
.faq {
    padding: 80px 0;
} 
.faq .more {
    margin-top: 60px;
}
.faq .more .title {
    color: #999999;
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 15px;
}

.delayp1 {
  animation-delay: .1s;
  -moz-animation-delay: .1s;
  -webkit-animation-delay: .1s;
  -o-animation-delay: .1s;
  transition-delay: .1s;
  -webkit-transition-delay: .1s;
  -moz-transition-delay: .1s;
  -o-transition-delay: .1s;
  padding: 0px;
}
.delayp2 {
  animation-delay: .2s;
  -moz-animation-delay: .2s;
  -webkit-animation-delay: .2s;
  -o-animation-delay: .2s;
  transition-delay: .2s;
  -webkit-transition-delay: .2s;
  -moz-transition-delay: .2s;
  -o-transition-delay: .2s;
}
.delayp3 {
  animation-delay: .3s;
  -moz-animation-delay: .3s;
  -webkit-animation-delay: .3s;
  -o-animation-delay: .3s;
  transition-delay: .3s;
  -webkit-transition-delay: .3s;
  -moz-transition-delay: .3s;
  -o-transition-delay: .3s;
}
.delayp4 {
  animation-delay: .4s;
  -moz-animation-delay: .4s;
  -webkit-animation-delay: .4s;
  -o-animation-delay: .4s;
  transition-delay: .4s;
  -webkit-transition-delay: .4s;
  -moz-transition-delay: .4s;
  -o-transition-delay: .4s;
}
.delayp5 {
  animation-delay: .5s;
  -moz-animation-delay: .5s;
  -webkit-animation-delay: .5s;
  -o-animation-delay: .5s;
  transition-delay: .5s;
  -webkit-transition-delay: .5s;
  -moz-transition-delay: .5s;
  -o-transition-delay: .5s;
}
.delayp6 {
  animation-delay: .6s;
  -moz-animation-delay: .6s;
  -webkit-animation-delay: .6s;
  -o-animation-delay: .6s;
  transition-delay: .6s;
  -webkit-transition-delay: .6s;
  -moz-transition-delay: .6s;
  -o-transition-delay: .6s;
}
.delayp7 {
  animation-delay: .7s;
  -moz-animation-delay: .7s;
  -webkit-animation-delay: .7s;
  -o-animation-delay: .7s;
  transition-delay: .7s;
  -webkit-transition-delay: .7s;
  -moz-transition-delay: .7s;
  -o-transition-delay: .7s;
}
.delayp8 {
  animation-delay: .8s;
  -moz-animation-delay: .8s;
  -webkit-animation-delay: .8s;
  -o-animation-delay: .8s;
  transition-delay: .8s;
  -webkit-transition-delay: .8s;
  -moz-transition-delay: .8s;
  -o-transition-delay: .8s;
}
.delayp9 {
  animation-delay: .9s;
  -moz-animation-delay: .9s;
  -webkit-animation-delay: .9s;
  -o-animation-delay: .9s;
  transition-delay: .9s;
  -webkit-transition-delay: .9s;
  -moz-transition-delay: .9s;
  -o-transition-delay: .9s;
}
.delay1s {
  animation-delay: 1s;
  -moz-animation-delay: 1s;
  -webkit-animation-delay: 1s;
  -o-animation-delay: 1s;
  transition-delay: 1s;
  -webkit-transition-delay: 1s;
  -moz-transition-delay: 1s;
  -o-transition-delay: 1s;
}
.delay2s {
  animation-delay: 2s;
  -moz-animation-delay: 2s;
  -webkit-animation-delay: 2s;
  -o-animation-delay: 2s;
  transition-delay: 2s;
  -webkit-transition-delay: 2s;
  -moz-transition-delay: 2s;
  -o-transition-delay: 2s;
}
 
#topcontrol {
  background: #34cbcf;
  color: #fff;
  text-align: center;
  display: inline-block;
  width: 35px;
  height: 35px;
  border: none;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
}
#topcontrol:hover {
  background: #28a5a8;
}
#topcontrol .fa {
  position: relative;
  top: 3px;
  font-size: 25px;
} 
@media (max-width: 768px) {
  .modal.modal-video .modal-dialog,
  .modal.modal-login .modal-dialog,
  .modal.modal-signup .modal-dialog {
    width: auto;
    margin-left: 10px;
    margin-right: 11px;
  }
  .modal.modal-video .modal-dialog,
  .modal.modal-logout .modal-dialog{
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
  .contact{
    font-size:15px;
  }
  #textArea {
    height: 10%;
}
  .text_message{
    margin: 17px !important;
  }


  }
  .price-plan .item .heading {
    background: #444444 none repeat scroll 0 0 padding-box;
    border-radius: 4px 4px 0 0;
    color: #fff;
    font-size: 22px;
    margin-bottom: 0;
    padding: 10px 0;
    position: relative;
    text-transform: uppercase;
}
.price-plan .item .content {
    background: #f5f5f5 none repeat scroll 0 0;
    padding: 30px;
}
.price-plan .item .btn {
    font-size: 16px;
    font-weight: bold;
    padding-left: 45px;
    padding-right: 45px;
    white-space: normal;
}
.price-plan .item .btn .extra {
    color: #0b2c2c;
    font-family: "Lato",arial,sans-serif;
    font-size: 12px;
    font-weight: normal;
    text-transform: none;
}
.btn.btn-red {
    background-color: #D84440;
    color: #fff;
    border-radius: 6px;
}
.btn.btn-cta-primary, .btn-cta-primary {
     background: #28a5a8 none repeat scroll 0 0;
    border: 2px solid #28a5a8;
    border-radius: 4px;
    color: #fff;
    text-transform: uppercase;
}
.btn-block.btn-cta-primary:hover, .btn-cta-primary:hover {
    background: #1e7d7f none repeat scroll 0 0;
    border: 2px solid #1e7d7f;
    color: #fff;
}
.btn.btn-cta.btn-cta-primary.text-center.newcheckin {
    color: #fff;
}
.btn.btn-cta.btn-cta-primary.text-center.newcheckin[disabled]{
  background-color: #28a5a8;
}
.btn.btn-cta.btn-cta-primary.text-center.newcheckin:hover, .btn.btn-cta.btn-cta-primary.text-center.newcheckin:focus{
  background: #1e7d7f none repeat scroll 0 0;
    border: 2px solid #1e7d7f;
    color: #fff;
}
.btn, a.btn {
    font-family: "Montserrat",sans-serif;
    padding: 8px 16px;
    transition: all 0.4s ease-in-out 0s;
}
.btn .fa, a.btn .fa {
    color: #fff;
    margin-right: 5px;
}
.btn.btn-cta-secondary .fa, .btn-cta-secondary .fa {
    color: #28a5a8;
    margin-left: 5px;
}
.btn.btn-cta.btn-cta-secondary.profile {
    background: #fff none repeat scroll 0 0;
    border: 2px solid #28a5a8;
    color: #28a5a8;
    border-radius: 4px;
    /*line-height: 2.42;*/
    text-transform: none;
   /* margin-top: 8px;*/
    
}
.btn.btn-cta.btn-cta-secondary.profile:hover {
    background: #fff none repeat scroll 0 0;
    border: 2px solid #1e7d7f;
    color: #1e7d7f;
}
.btn.btn-cta-secondary:hover .fa, .btn-cta-secondary:hover .fa {
  background-color:none;
    color: #1e7d7f;
}
.btn.btn-cta.btn-warning.text-center {
    background-color: #f0ad4e;
    border-bottom-color: #eea236;
    border-left-color: #eea236;
    border-radius: 4px;
    border-top-color: #eea236;
    color: #fff;
}
/*logout popup*/
 .btn-block.btn-cta-secondary{
    background: #fff none repeat scroll 0 0;
    border: 2px solid #28a5a8;
    color: #28a5a8;
    border-radius:4px;
    text-transform: none;
}
.btn.btn-block.btn-cta-secondary.text-center:hover{
   background: #fff none repeat scroll 0 0;
    border: 2px solid #1e7d7f;
    color: #1e7d7f;
 }
 .btn.btn-block.btn-cta-primary:hover{
   background: #1e7d7f none repeat scroll 0 0;
    border: 2px solid #1e7d7f;
    color: #fff;
 }
 /*danger button*/
 .btn.btn-cta.btn-danger {
    background-color: #d9534f;
    border-color: #d43f3a;
    color: #fff;
    box-shadow: none;
}
 .btn.btn-cta.btn-danger:hover, .btn.btn-cta.btn-danger:focus {
     background-color: #c9302c;
    border-color: #ac2925;
    color: #fff;
}
/*welcome popup close button*/
.btn-block.btn-cta-secondary.nocontacts-modal-close {
   background: #fff none repeat scroll 0 0;
    border: 2px solid #28a5a8;
    color: #28a5a8;
    border-radius: 4px;
    line-height: 1.42;
    text-transform: none;
}
.btn-block.btn-cta-secondary.nocontacts-modal-close:hover{
   background: #fff none repeat scroll 0 0;
    border: 2px solid #1e7d7f;
    color: #1e7d7f;
}

.btn.btn-block.btn-cta-primary.nocontacts-modal-close {
    line-height: 1.42;
    background: #28a5a8 none repeat scroll 0 0;
    border: 2px solid #28a5a8;
    border-radius: 4px;
    color: #fff;
}
.btn.btn-block.btn-cta-primary.nocontacts-modal-close:hover{
  background: #1e7d7f none repeat scroll 0 0;
    border: 2px solid #1e7d7f;
    color: #fff;
}

.footer .footer-col.links li {
    margin-bottom: 10px;
}
/*bootstrap modal*/
.modal-content {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);
}
.modal-content .modal-header {
    border-bottom: 1px solid #e5e5e5;
    min-height: 16.43px;
    padding: 17px;
}
p {
    color: #444444;
    font-size: 16px;
}
.modal-content .modal-body + .modal-footer {
    padding-top: 15px;
}
.modal-content .modal-footer {
    border-top: 1px solid #e5e5e5;
 
}
.modal.modal-login .modal-header, .modal.modal-signup .modal-header {
    border: medium none;
    padding: 15px;
}
/*Validation error*/
.alert.alert-danger {
    background-color: #f2dede;
    border-color: #ebccd1;
    color: #a94442;
    white-space: nowrap;
      
}
.alert {
    border: 1px solid transparent;
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 15px;
}
.alert-content {
    background-color: #fcf8e3;
    border: 1px solid #e4e1cb;
    color: #8A6D3B;
    padding: 13px;
    font-weight: bold;
}
.alert.alert-warning-new{
  background-color: #fcf8e3;
  border-color: #faebcc;
  color: #8a6d3b;
}
.modal-backdrop.in {
    opacity: 0.3;
}
.modal-backdrop {
    background-color: #000;
}
/*disable button color*/
.btn.btn-primary.btn-lg.btn-block.btn-raised[disabled] {
    background-color: #28a5a8;
}
/*signup form icons*/
.modal.modal-login .form-group.phone::before, .modal.modal-signup .form-group.phone::before {
    color: #999999;
    content: "\f095";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    left: 10px;
    line-height: 1;
    position: absolute;
    top: 12px;
}
.modal.modal-login .form-group.edit::before, .modal.modal-signup .form-group.edit::before {
    color: #999999;
    content: "\f15c";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    left: 10px;
    line-height: 1;
    position: absolute;
    top: 12px;
}
/*google map auto complete*/
.pac-container {
background-color: #FFF;
z-index: 1052 !important;
position: fixed;
display: inline-block;
float: left;
}
.form-group{
  padding-bottom: 0;
}
.note.form-group {
    margin-top: 107px;
}
.subscription.form-group {
    margin-top: -15px;
}
.error-msg {
    float: left;
    margin-bottom: 16px;
}
.close.close-service {
    background: #28a5a8 none repeat scroll 0 0;
    border: 2px solid #28a5a8;
    color: #fff;
    padding: 2px 7px;
    opacity: 1;
}
/*inline edit-profile css merge into this file*/
section.edit-profile {
    display: block;
    padding: 100px 0 0px;
}
.cancel{
  max-width: 250px;
}
.text-center.cancel-link.returnToDashBoard {
    padding-left: 0;
    padding-right: 0;
    width: 90%;
}
#profileEditPW {
    display: block;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
}
#profileEditPWCancel {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
}
#profileEditPWSave{
  width:100%;
  padding-left:0;
  padding-right:0;
}
#profileEditPINCancel{
   padding-left: 0;
    padding-right: 0;
    width: 100%;
}
#profileEditPIN{
    display: block;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
}
#profileEditPINSave{
  width:100%;
  padding-left:0;
  padding-right:0;
}
#profileEditPhoneCancel{
   padding-left: 0;
    padding-right: 0;
    width: 100%;
}
#profileEditPhone{
    display: block;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
}
#profileEditPhoneSave{
  width:100%;
  padding-left:0;
  padding-right:0;
}
#profileEditAddress{
  display: block;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
}
#profileEditAddressSave{
  padding-left: 0;
    padding-right: 0;
    width: 90%;
}
#profileEditAddressCancel{
   padding-left: 0;
    padding-right: 0;
    width: 90%;
}
#showPriceList{
  width:90%;
  padding-left:0;
  padding-right:0;
}
.address {
    float: left;
    margin-left: -12px; 
}
.add-label {
    font-size: 16px;
    font-weight: bold;
}
.alert.alert-success.subscription-success {
    background-color: #e0efda;
    color: #6e8c68;
}
/*inline emergency-contact css merge into this file*/
section.emergency-contact {
    display: block;
    padding: 100px 0 0px;
}
#addNewContactCell{
  width:90%;padding-left:0;padding-right:0;
}
#addNewContactLan{
  width:90%;padding-left:0;padding-right:0;
}
#cancelContact{
  width:90%;padding-left:0;padding-right:0;margin-bottom:5px;
}
#addNewContactSave{
  width:90%;padding-left:0;padding-right:0;
}
#addNewContactStart{
  width:50%;padding-left:0;padding-right:0;
}
body .container .well-custom, body .container-fluid .well-custom {
    padding: 10px;
}
body .container .well-custom, body .container-fluid .well-custom, body .container .jumbotron, body .container-fluid .jumbotron, body .container .well-custom, body .container-fluid .well-custom, body .container .jumbotron-default, body .container-fluid .jumbotron-default {
    background-color: #f5f5f5;
}
body .container .well-custom, body .container-fluid .well-custom, body .container .jumbotron, body .container-fluid .jumbotron {
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05) inset;
    margin-bottom: 20px;
}
.well-custom .form-group{
   margin: 10px;
}
.col-sm-5.name {
    margin-top: 10px;
}
.col-sm-2.warning {
    margin-top: 5px;
}
.col-sm-2.emergency-phone {
   margin-top: 10px;
}
.btn.btn-cta.btn-cta-secondary.text-center.contactEdit{
   padding: 0 5px 0 10px;
}
.btn.btn-cta.btn-cta-primary.text-center.contactDelete {
  padding: 0 5px 0 10px;
}
.alert-dismissable .close, .alert-dismissible .close{
  right: -3px;
}
.alert.alert-warning.alert-reject{
  background-color: #ffe1ae;
    color: #ff5722;
  }


/*inline new-checking css merge into this file*/
section.new-checkin {
  padding: 100px 0px 0px; display: block;
}
.time {
    padding-left: 0;
    padding-right: 0;
}
.colon{
  padding-left:0;padding-right:0;font-size: 30px;
}
.heading.wizardblockheading{
  display: block;
}
#alertwizardSMSAlert{
  width:90%;padding-left:0;padding-right:0;
}
#alertwizardCallAlert{
  width:90%;padding-left:0;padding-right:0;
}
#alertwizardsetTimeAmPm{
  width:90%;padding-left:0;padding-right:0;
}
.contact-dropdown{
  display: block;
}
#divalertwizardNoContacts{
display: block;
}
#emergency .profile.editContact{
 width:90%;padding-left:0;padding-right:0;
}
#wizardLoadNext{
  width:90%;padding-left:0;padding-right:0;
}
#wizardLoadPrev{
  width:90%;padding-left:0;padding-right:0;
}
.active-alert {
    background-color: #d9edf7;
    border-color: #bce8f1;
    color: #31708f;
    border-radius: 4px;
    padding: 6px;
}
/*inline main-dashboard css merge into this file*/
section.main-dashboard {
 padding: 100px 0px 0px; display: block;
}
#check-in{
  width:90%;padding-left:0;padding-right:0;
}
#emergency{
  padding-left:0px; padding-right:0px;
}
#dashboardProfileProfile{
  padding-left:0px; padding-right:0px;
}
#editMyProfile{
  width:90%;padding-left:0;padding-right:0;
}
.min-height{
  min-height:12px;
}
#wizardControls{
  display: block;
}
/*Checkbox*/
input[type="checkbox"] {
  display: none;
}
input[type="checkbox"] + label {
    background: url("/images/check.png") no-repeat;
    display: inline-block;
    height: 15px;
    width: 52%;
    margin: 4px 0 7px;
    cursor: pointer;
    font-size: 16px;
    font-weight:bold;
    color:#28a5a8;
    line-height: 1.1;
    white-space: nowrap;
}
input[type=checkbox]:checked + label{
  background: url("/images/check-mark.png") no-repeat;
   display: inline-block;
    height: 15px;
    width: 52%;
    margin: 4px 0 7px;
    cursor: pointer;
    font-size: 16px;
    font-weight:bold;
    color:#28a5a8;
    line-height: 1.1;
}
/* Social Bar */
#social {padding-top: 8%;}

/* Footer Invisible body gaurd*/
.invisible-guard h2 {font-weight: normal;font-size: 20px;color: #ffffff;margin-top: 0px;}
/* Media Query */
@media (max-width: 980px) {
  .social .btn.btn-danger.btn-raised.btn-lg {
    position: relative;
    top: -12px;
  }
}

@media screen and (max-width: 639px) {
  .btn.btn-cta.btn-cta-secondary.text-center.contactEdit {
    padding: 3px 5px 3px 10px;
  }

  .btn.btn-cta.btn-cta-primary.text-center.contactDelete {
    padding: 3px 5px 3px 10px;
  }
  .edslider {top: 50px;}
  .why {
      padding-top: 13%;
  }
  #social {
      padding-top: 15%;
  }
  #promo-title {font-size: 18px;}
  .animated.fadeInRight.summary {font-size: 16px;}
  .promo .social {
      color: #fff;
      height: 37%;
      position: absolute;
      width: 100%; 
  }
  /*.social .btn.btn-danger.btn-raised.btn-lg {
      position: relative;
      top: 0px;
  }*/
  .navbar-brand > img {
      
      height: 100%;
      max-width: 88px;
      width: 100%;
  }
  section.main-dashboard {
   padding: 66px 0px 0px; display: block;
  }
}

@media screen and (max-width: 480px) {
  .form-group .col-xs-9 {
    width: 100%;
  }
  .text_message {
    margin: 17px;
  }
  .promo .social {top: 35.6%;}
}
@media only screen and (min-width : 700px) and (max-width: 736px) {
  .promo .social {
      top: 66%;
  }
}
@media only screen and (min-width : 361px) and (max-width: 667px) {
  .promo .social {
      top: 64%;
  }
}
@media only screen and (min-width : 360px) and (max-width: 640px) {
  .promo .social {top: 47.6%; }
  #promo .edslider .controls {
    top: 104%;
  }
    .animated.fadeInRight.summary {
  font-size: 18px;
}
  #promo-title {
  font-size: 25px;
}
}
@media only screen and (min-width: 481px) and (max-width: 568px) {
  .promo .social {
      top: 42.4%;
  }
}
@media only screen and (min-width: 500px) and (max-width: 560px) {
  .promo .social {top: 37.5%}

}
@media only screen and (min-width : 320px) and (max-width: 480px) {
  .promo .social {top: 35.6%;}
  #promo .edslider .controls {top: 102%;}
  .promo .social {height: 35%;}
}
@media only screen and (min-width: 400px) and (max-width: 414px) {
  .promo .social {
      top:30.6%;
  }
}
@media only screen and (min-width: 325px) and (max-width: 375px) {
  .promo .social {
      top: 27.6%;
  }
}
@media only screen and (min-width: 375px) and (max-width: 667px) {
 #promo-title {font-size: 25px;}
 .animated.fadeInRight.summary {font-size: 18px;}
}

@media screen and (max-width: 360px) {
  .promo .social {top: 26.6%;}
   #promo .edslider .controls {top: 91%;}
}


@media screen and (max-width: 320px) {
  .promo .social {
    top: 23.6%;
  }
  #promo .edslider .controls {
    top: 85%;
  }
}

@media (max-width: 480px) {
  .modal {
      height: 500px; /* Set a default max height of the modal (adjusted later)*/
      position: fixed; /* Display modal in the centre of your screen */
      overflow-y: scroll; /*  Ensure that the modal is scroll-able */
      -webkit-overflow-scrolling: touch; /* Avoid having to use 2 finger scroll on iOS    */
  }
  .modal.fade.in{
      top: 5px; /* Use more screen real estate */
  }
  .modal-body{
      /* Increase the max height of the modal body to try & avoid both it,
       * and the modal container having scroll bars which results in odd behavior */ 
      max-height: 2400px; 
  }
   #promo-title {font-size: 18px;}
   .contact-main { padding-top: 68px;}
   .safety_content .title {font-size: 19px;}
   .safety_content p {font-size: 16px;}
   .personal-safety-feature-list ul > li {font-size: 16px;}
   .animated.fadeInRight.summary {font-size: 13px;padding-top: 0px;}
}
@media screen and (max-width:639px){
  .personal-safety-feature-list{width: 100%;}
}
@media screen and (min-width: 640px) and (max-width: 767px) {
  #promo-title {font-size: 25px;}
  .animated.fadeInRight.summary {font-size: 18px;}
}
@media screen and (max-width: 767px) {
  .why .benefits, .why .whois {padding: 0px;}
  .safety_content .title {font-size: 22px;}
  .safety_content p {font-size: 16px;}
  .personal-safety-feature-list ul > li {font-size: 16px;}
   .contact-main { padding-top: 68px;}
  .navbar-brand > img {
    height: 100%;
    max-width: 88px;
    width: 100%;
  }

  .edslider {
      top: 50px;
  }
  #promo .edslider .controls {display:none;}
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .animated.fadeInRight.summary {
      font-size: 20px;
    }
      #promo-title {
      font-size: 30px;
    }
  .safety_content .title {font-size: 22px;}
  .safety_content p {font-size: 16px;}
  .personal-safety-feature-list ul > li {font-size: 18px;}
  .contact-main { padding-top: 80px;}
  .edslider {
    top: 51px;
  }
  .promo .social {top: 67%;}
  .why {
      padding-top: 8%;
  }
  #social {
      padding-top: 12%;
  }
  .invisible-guard h2 {font-size: 17px;}
  #promo-title {font-size: 30px;}
  .animated.fadeInRight.summary {font-size: 20px;}
}

@media (max-width: 1024px) {
  .promo .social {
      color: #fff;
      height: 40%;
      position: absolute;
      /*top: 67%;*/
      width: 100%;
  }

  .tel.col-md-12 > h2 {
      font-size: 25px;
  }
  #textArea {                                                                                                                      
      height: 19%;
  }
  
  .navbar-right {margin-top: 1px;}
 
}
/* Now adjust the height so it handles various screen sizes & orientations */
/* You could make this as granular as you like, or have it more granular at common screen sizes
 * but it should start at the height we set on .modal (i.e. 500px) & work down */
@media (max-width: 480px) and (max-height: 500px){.modal{ height: 450px}}
@media (max-width: 480px) and (max-height: 450px){.modal{ height: 400px}}
@media (max-width: 480px) and (max-height: 400px){.modal{ height: 350px}}
@media (max-width: 480px) and (max-height: 350px){.modal{ height: 300px}}
@media (max-width: 480px) and (max-height: 300px){.modal{ height: 250px}}
@media (max-width: 480px) and (max-height: 250px){.modal{ height: 200px}}
@media (max-width: 480px) and (max-height: 200px){.modal{ height: 150px}}

#checkinErrorDiv .text-danger.ng-binding {
  white-space: normal; 
}


